
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt, faPhone, faUser } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faGithub } from "@fortawesome/free-brands-svg-icons";
import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from '@themesberg/react-bootstrap';
import { StaticImage } from "gatsby-plugin-image"
import { Link, navigate } from 'gatsby';
import useAuth from "../hooks/userAuth";
import Logo from "../components/Logo"
import BgImage from "../assets/img/illustrations/signin.svg";


export default () => {

  const { register } = useAuth()
  const [message, setMessage] = useState("")
  const [payload, setPayload] = useState({
    username: "",
    password: "",
    contact: '',
    fullName: ''
  })

  const handleTextField = (e, payloadKey) => {
    setPayload({
      ...payload,
      [payloadKey]: e.target.value
    })
  }
  const handleBtn = async () => {
    try {
      const res = await register(payload?.username, payload?.password, payload?.fullName, payload?.contact)
        .then(e => navigate('/signin'))
    } catch (e) {
      setMessage(e.message)
    }
  }

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <Logo />
                  <h3 className="mb-0">Create an account</h3>
                </div>
                <Form className="mt-4">
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Full Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control value={payload?.fullName} onChange={(e) => handleTextField(e, 'fullName')} autoFocus required type="text" placeholder="John Doe" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Mobile Number</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faPhone} />
                      </InputGroup.Text>
                      <Form.Control value={payload?.contact} onChange={(e) => handleTextField(e, 'contact')} required type="text" placeholder="mobile number" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control value={payload?.username} onChange={(e) => handleTextField(e, 'username')} required type="email" placeholder="example@company.com" />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control value={payload?.password} onChange={(e) => handleTextField(e, 'password')} required type="password" placeholder="Password" />
                    </InputGroup>
                  </Form.Group>
                  <Button onClick={handleBtn} variant="primary" className="w-100">
                    Sign up
                  </Button>
                  <div className="text-center" style={{ color: 'red' }}>
                    {
                      message
                    }
                  </div>
                </Form>
                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to="/signin" className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
